'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get('/authority/field/list', { params, code });
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/fieldRule/authority/${roleId}`, { code });
  },
  add(code, data) {
    return http.post('/authority/field', data, { code });
  },
  update(code, data) {
    return http.put('/authority/field', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/field', { params, code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/fieldRule/authority', data, { code });
  },
};
