<template>
  <search-form :items="items" @fnSearch="handleFnSearch" />
  <grid
    ref="gridRef"
    :columns="columns"
    :code="$route.path"
    :url="url"
    :search="search"
    :btn-operation="btnOperation"
    :scroll="{ y: gridHeight }"
  >
    <template #readonly="{ text }">
      <a-tag :color="text ? 'blue' : 'purple'">
        {{ text ? '是' : '否' }}
      </a-tag>
    </template>
    <template #status="{ text }">
      <a-tag :color="text ? 'success' : 'error'">
        {{ text ? '启用' : '禁用' }}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
  <transfer-modal
    v-model:visible="userVisible"
    :url="userUrl"
    :leftColumns="leftColumns"
    :rightColumns="rightColumns"
    :init-value="activeUsers"
    :filter-option="
      (inputValue, { code, name }) =>
        mobile.indexOf(inputValue) !== -1 ||
        email.indexOf(inputValue) !== -1 ||
        name.indexOf(inputValue) !== -1
    "
    @fnOk="handleFnOkByUser"
  />
  <iss-role-edit
    v-model:visible="editVisible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
  <iss-role-config v-model:visible="configVisible" :init-value="activeItem" />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { message, Tag } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import TransferModal from '@/components/transferModal';
import IssRoleEdit from './components/edit';
import IssRoleConfig from './components/config';
import userApi from '@/api/user';
import roleApi from '@/api/role';

export default {
  components: {
    ATag: Tag,
    SearchForm,
    Grid,
    Operation,
    TransferModal,
    IssRoleEdit,
    IssRoleConfig,
  },
  setup() {
    const store = useStore();
    const gridRef = ref();
    const state = reactive({
      search: {},
      editVisible: false,
      userVisible: false,
      configVisible: false,
      activeItem: {},
      activeUsers: [],
    });
    const deleteItems = ids => {
      console.log(store.state.account.user);
      // if (ids.includes(store.state.account.user.roleCode)) {
      //   message.warning('当前删除项中存在当前登录角色，不能删除');
      // } else {
      roleApi.delete('role:delete', { ids }).then(() => {
        message.success('操作成功');
        gridRef.value.refreshGrid();
      });
      // }
    };
    return {
      items: [
        { key: 'code', label: '编码' },
        { key: 'name', label: '名称' },
      ],
      columns: [
        { dataIndex: 'code', title: '编码' },
        { dataIndex: 'name', title: '名称' },
        {
          dataIndex: 'readonly',
          title: '是否内置',
          width: 100,
          slots: { customRender: 'readonly' },
        },
        {
          dataIndex: 'status',
          title: '状态',
          width: 80,
          slots: { customRender: 'status' },
        },
        { dataIndex: 'describe', title: '描述' },
        {
          dataIndex: 'id',
          fixed: 'right',
          title: '操作',
          width: 170,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'role:add',
          fnClick: () => {
            state.editVisible = true;
            state.activeItem = {};
          },
        },
      ],
      options: [
        {
          type: 'update',
          permission: 'role:update',
          fnClick: record => {
            state.editVisible = true;
            state.activeItem = { ...record };
          },
        },
        {
          type: 'auth',
          label: '授权',
          icon: 'UserOutlined',
          permission: 'role:auth',
          fnClick: record => {
            state.activeItem = record;
            userApi.getUserIdByRoleId('role:auth', record.id).then(data => {
              state.userVisible = true;
              state.activeUsers = data;
            });
          },
        },
        {
          type: 'config',
          label: '配置',
          icon: 'SettingTwoTone',
          permission: 'role:config',
          fnClick: record => {
            state.configVisible = true;
            state.activeItem = record;
          },
        },
        {
          type: 'delete',
          permission: 'role:delete',
          fnClick: ({ id }) => deleteItems([id]),
        },
      ],
      leftColumns: [
        { dataIndex: 'name', title: '名称' },
        { dataIndex: 'mobile', title: '手机' },
        { dataIndex: 'email', title: '邮箱' },
      ],
      rightColumns: [{ dataIndex: 'name', title: '名称' }],
      url: roleApi.pageUrl,
      userUrl: `${userApi.pageUrl}?size=100000&status=true`,
      gridHeight: document.body.clientHeight - 323,
      gridRef,
      ...toRefs(state),
      handleFnSearch: value => {
        state.search = value;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        value.id && (type = 'update');
        roleApi[type](`role:${type}`, value).then(() => {
          message.success('操作成功');
          gridRef.value.refreshGrid();
        });
      },
      handleFnOkByUser: userIdList => {
        roleApi
          .saveRoleUser('role:auth', {
            type: 1,
            userIdList,
            roleIdList: [state.activeItem.id],
          })
          .then(() => message.success('操作成功'));
      },
    };
  },
};
</script>

<style lang="less" scoped></style>
